import i18n from '@/i18n';
import { getDateFromFullDate, isValidDateTime } from '@/utils/format';
import { regexEmail, regexUrl, regexKatakana, regexNumberWithHyphens, regexPassword } from '@/utils/regex';

function validateField(field, isValid = true) {
    let valueTrim = field?.valueSync ? String(field.valueSync).trim() : null;

    if (field?.typesValidate?.includes('required')) {
        if (!valueTrim) {
            field.isRequired = true;
            field.error = true;
            isValid = false;
        }
    }

    if (field?.typesValidate?.includes('max10') && valueTrim && valueTrim.length > 10) {
        field.errorCustomMess = i18n.t('validateField.max10', {
            field: field.errorField
        });
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('max20') && valueTrim && valueTrim.length > 20) {
        field.errorCustomMess = i18n.t('validateField.max20', {
            field: field.errorField
        });
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('max255') && valueTrim && valueTrim.length > 255) {
        field.errorCustomMess = i18n.t('validateField.max255', {
            field: field.errorField
        });
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('number-hyphens') && valueTrim && valueTrim.length <= 20 && !regexNumberWithHyphens(valueTrim)) {
        field.errorCustomMess = i18n.t('validateField.incorrectFormat', {
            field: field.errorField
        });
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('password') && valueTrim && valueTrim.length <= 255 && !regexPassword(valueTrim)) {
        field.isPassword = true;
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('email') && valueTrim && valueTrim.length <= 255 && !regexEmail(valueTrim)) {
        field.isEmail = true;
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('url') && valueTrim && valueTrim.length <= 255 && !regexUrl(valueTrim)) {
        field.isUrl = true;
        field.error = true;
        isValid = false;
    }

    if (field?.typesValidate?.includes('katakana') && valueTrim && valueTrim.length <= 255 && !regexKatakana(valueTrim)) {
        field.isKatakana = true;
        field.error = true;
        isValid = false;
    }

    // date validation
    if (field?.typesValidate?.includes('requiredDate')) {
        if (!valueTrim) {
            field.error = true;
            field.errorText = i18n.t('validateField.requiredDate', {
                field: field.errorField
            });
            isValid = false;
        }
    }

    if (field?.typesValidate?.includes('date')) {
        if (valueTrim) {
            if (!getDateFromFullDate(valueTrim)) {
                field.error = true;
                field.errorText = i18n.t('validateField.incorrectFormat', {
                    field: field.errorField
                });
                isValid = false;
            }
        }
    }

    if (field?.typesValidate?.includes('dateTime')) {
        if (valueTrim) {
            if (!isValidDateTime(valueTrim)) {
                field.error = true;
                field.errorText = i18n.t('validateField.incorrectFormat', {
                    field: field.errorField
                });
                isValid = false;
            }
        }
    }

    if (field?.typesValidate?.includes('requiredSelect')) {
        if (!field.valueSync) {
            field.error = true;
            field.isRequired = true;
            isValid = false;
        }
    }

    return isValid;
}

function validateAllField(form) {
    let isValid = true;

    Object.keys(form).forEach((key) => {
        const field = form[key];
        if (key.startsWith('configField')) {
            isValid = validateField(field, isValid);
        }

        // Nếu là một mảng, gọi đệ quy validateAllField cho từng phần tử
        if (Array.isArray(field)) {
            field.forEach((item) => {
                if (typeof item === 'object' && item !== null) {
                    // Gọi lại validateAllField đệ quy cho từng object trong mảng
                    isValid = validateAllField(item) && isValid;
                }
            });
        }
        // // Nếu là một object, gọi đệ quy validateAllField
        else if (typeof field === 'object' && field !== null) {
            isValid = validateAllField(field) && isValid;
        }
    });

    return isValid;
}

export { validateAllField, validateField };

function regexNumber(zipCode) {
    return zipCode.replace(/-{2,}/g, '-').replace(/[^0-9-]/g, '');
}

/* eslint-disable no-useless-escape */
function regexUrl(url) {
    const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    return expression.test(url);
}

function regexEmail(email) {
    const re =
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

function regexPassword(password) {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/;
    return passwordRegex.test(password);
}

function regexKatakana(katakana) {
    const katakanaRegex = /^[\u30A0-\u30FF\s]+$/u;
    return katakanaRegex.test(katakana);
}

function regexNumberWithHyphens(input) {
    const numberWithHyphensRegex = /^[\d-]+$/;
    return numberWithHyphensRegex.test(input);
}

export { regexNumber, regexUrl, regexPassword, regexKatakana, regexNumberWithHyphens, regexEmail };

<template>
    <div>
        <ModalCommon :id="`configModalRule-${config?.id ?? ''}`" :config="config" @save="formSubmit()" @close="handleClose()">
            <form class="needs-validation" autocomplete="off" @submit.prevent="formSubmit">
                <div class="form-group row">
                    <label class="col-sm-2 mw--form" for="validationCustom05">配置 <span class="text-danger">*</span></label>
                    <div class="col-sm-6">
                        <Multiselect
                            :id="`select-option`"
                            :value.sync="dataForm.option"
                            :options="listDataMasterOption"
                            :config.sync="configForm.configFieldOption"
                            @remove="handleValidate(configForm.configFieldOption)"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-2 mw--form" for="validationCustom05">区分 <span class="text-danger">*</span></label>
                    <div class="col-sm-6">
                        <Multiselect
                            :id="`select-classification`"
                            :value.sync="dataForm.classification"
                            :options="listDataMasterClassic"
                            :config.sync="configForm.configFieldClassic"
                            @remove="handleValidate(configForm.configFieldClassic)"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-2 mw--form" for="validationCustom05">デフォルト有無 </label>
                    <div class="col-sm-10">
                        <InputCheckBox :model.sync="dataForm.is_default" :id="`is_default1`" :value="dataForm.is_default" :label="``" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-2 mw--form" for="validationCustom05">条文・細則<span class="text-danger">*</span></label>
                    <div class="col-sm-10">
                        <div class="row">
                            <div class="col-sm-8 pr-0">
                                <Editor
                                    v-if="isShowEditor"
                                    :model.sync="dataForm.detail"
                                    :configError.sync="configForm.configFieldDetail"
                                    @init="onEditorInit"
                                    @change="handleValidate(configForm.configFieldDetail)"
                                />
                            </div>
                            <div class="col-sm-4 pr-0">
                                <ul style="list-style: none; padding-left: 0">
                                    <li
                                        @click="handleCopy(item)"
                                        style="border: 1px solid #ddd; display: inline-block; margin-right: 8px; margin-bottom: 8px; cursor: pointer"
                                        v-for="item in shortCode"
                                        :key="item"
                                    >
                                        {{ item }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ModalCommon>
    </div>
</template>

<script>
import ModalCommon from '@/components/Modal/main.vue';
import Editor from '@/components/Editor/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';

import { masterMethods } from '@/state/helpers';
import { showMessage } from '@/utils/messages';
import { validateField, validateAllField } from '@/utils/validate';
import { configForm, shortCode, initDataForm } from './index';
import { InputCheckBox } from '@/components/Input';

export default {
    name: 'ModalRule',
    components: {
        ModalCommon,
        Editor,
        Multiselect,
        InputCheckBox
    },
    props: {
        dataRule: {
            type: Object,
            default: () => null
        },
        listDataMasterOption: {
            type: Array,
            default: () => []
        },
        listDataMasterClassic: {
            type: Array,
            default: () => []
        },
        config: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            shortCode: shortCode,
            dataForm: this.dataRule,
            configForm: configForm(),
            editorObj: null,
            isShowEditor: false,
            isLoading: false
        };
    },
    methods: {
        ...masterMethods,

        onEditorInit(editor) {
            this.editorObj = editor;
        },

        handleCopy(data) {
            this.editorObj.insertContent(data);
        },

        removeHtmlTags(str) {
            return str.replace(/<\/?[^>]+(>|$)/g, '');
        },

        handleClose() {
            this.config.model = false;
            this.dataForm = initDataForm();
            this.configForm = configForm();
        },

        handleValidate(config) {
            validateField(config);
        },

        formSubmit() {
            let checkValidate = validateAllField(this.configForm);
            if (!checkValidate || this.isLoading) return;
            this.isLoading = true;
            let object = JSON.parse(JSON.stringify(this.dataForm));
            object.detail = this.removeHtmlTags(object.detail);
            object.option = {
                id: object.option.id
            };
            object.classification = {
                id: object.classification.id
            };
            if (object.id) {
                this.updateRule2(object).then((data) => {
                    this.isLoading = false;
                    if (data.code !== 200) {
                        showMessage(data.code, this.$bvToast, this.$t(data.message));
                        return;
                    }
                    showMessage(data.code, this.$bvToast, '更新されました。');
                    this.handleClose();
                    this.$emit('refresh');
                });
            } else {
                this.createRule2(object).then((data) => {
                    this.isLoading = false;
                    if (data.code !== 200) {
                        showMessage(data.code, this.$bvToast, this.$t(data.message));
                        return;
                    }
                    showMessage(data.code, this.$bvToast, '登録されました。');
                    this.handleClose();
                    this.$emit('refresh');
                });
            }
        }
    },
    watch: {
        'config.model': {
            handler: function (newShow) {
                if (newShow) {
                    setTimeout(() => {
                        this.isShowEditor = true;
                    }, 200);
                } else {
                    this.isShowEditor = false;
                }
            }
        },

        dataRule: {
            handler: function (newData) {
                this.dataForm = newData;
                this.config.titleBtn = newData?.id ? '保存' : '登録';
            },
            immediate: true,
            deep: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
